<template>
    <div class="mainWrapper pb-0">
        <section class="blockElement space position-relative reatesPage">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-between">
                            <h1 class="mb-2 f-22">{{$t('zuluRanking.systemTest1')}}</h1>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="static">
                            <h5>{{$t('zuluRanking.systemTest2')}}</h5>
                            <p>{{$t('zuluRanking.systemTest3')}}</p>
                            <p>{{$t('zuluRanking.systemTest4')}}</p>
                            <p v-html="$t('zuluRanking.systemTest5')"></p>
                            <ul class="dotList">
                                <li v-html="$t('zuluRanking.systemTest6')"></li>
                                <li v-html="$t('zuluRanking.systemTest7')"></li>
                                <li v-html="$t('zuluRanking.systemTest8')"></li>
                            </ul>
                            <p>{{$t('zuluRanking.systemTest9')}}</p>
                            <p>{{$t('zuluRanking.systemTest10')}}</p>
                            <p>{{$t('zuluRanking.systemTest11')}}</p>
                            <h5>{{$t('zuluRanking.systemTest12')}}</h5>
                            <p>{{$t('zuluRanking.systemTest13')}}</p>
                            <ul class="dotList">
                                <li>{{$t('zuluRanking.systemTest14')}}</li>
                                <li>{{$t('zuluRanking.systemTest15')}}</li>
                                <li>{{$t('zuluRanking.systemTest16')}}</li>
                            </ul>
                            <p>{{$t('zuluRanking.systemTest17')}}</p>
                            <p>{{$t('zuluRanking.systemTest18')}}</p>
                            <p>{{$t('zuluRanking.systemTest19')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>